import { defineStore } from "pinia";
import { computed, ref } from "vue";
import axios from "axios";
import { useLocalStorage, useSessionStorage } from "@vueuse/core";
import { useSwalStore } from "@/store/swal";
import logo from "@/assets/logo.png";
import { useAddressStore } from "@/store/address";
import { useAuthStore } from "@/store/auth";
import { useOrderStore } from "@/store/order";
import router from "@/router";

export const useProductStore = defineStore("product", () => {
	//region states
	const env = process.env;
	const configs = {
		headers: {
			apiKey: env.VUE_APP_API,
		},
	};
	const baseUrl = env.VUE_APP_API_URL_LOCAL;
	const apiUrl = baseUrl + "products-all?";
	const productID = ref(null);
	const productDetail = ref(null);
	const productViewed = ref([]);
	const productViewedFilter = ref([]);
	const products = ref([]);
	const productsSearch = ref([]);
	const productsLiked = ref([]);
	const productReviews = ref([]);
	const selectedTabReview = ref([]);
	const sales_product = ref([]);
	const salesHome = ref([]);
	const recommendationHome = ref([]);
	const drinkHome = ref([]);
	const recommendations = ref([]);
	const newest_products = ref([]);
	const top_product = ref([]);
	const newest_products_home = ref([]);
	const drinks = ref([]);
	const favorite = ref(false);
	const category = ref([]);
	const updateSuccess = ref([]);
	const useCoupon = ref([]);
	const categoryProduct = ref([]);
	const modalKupon = ref("");
	const showDesc = ref(false);
	const showReview = ref(false);
	const showCatatan = ref(false);
	const couponProduct = ref([]);
	const token = ref(useLocalStorage("token", ""));
	const cart = ref(useSessionStorage("cart", []));
	const cart_number = ref(0);
	const cart_number_items = ref(0);
	const cart_summary = ref(null);
	const trx_id = ref(null);
	const showPaymentMethods = ref(false);
	const catatanData = ref({
		image: "",
		title: "",
		quantity: "",
		product_id: "",
		note: "",
	});
	const selectedProductVariant = ref({
		attribute_id: null,
		id: null,
		value: null,
	});
	const selectedPaymentMethods = ref({
		name: null,
		code: null,
		category: null,
	});
	const selectedKupon = ref({
		name: null,
		code: null,
	});
	const selectedShippingMethods = ref({
		service: false,
		service_type: false,
		name: "Metode pengiriman",
		amount: 0,
		formatted_amount: "0",
		distance: 0,
		formatted_distance: "0",
		estimatedTimeline: [],
		info: "Pilih metode pengiriman",
	});
	const selectedNavbarTab = ref("");
	const selectedProductFilter = ref("");
	const selectedLokasiFilter = ref("");
	const selectedFilterType = ref("");
	const appliedCoupons = ref({});

	//end of region states

	//region getters
	const getProducts = computed(() => products);
	const getProductDetail = computed(() => productDetail);
	//end of region getters

	// function sortSalesBy'Price() {
	// 	sProduct.sales.sort((a, b) => a.price - b.price);
	//   }

	//region actions
	function setSelectedShippingMethods(data) {
		selectedShippingMethods.value = data;
	}

	async function getSaleProducts(limit = 10, query = null, updateSales = false) {
		let url = "type=sale&limit=" + limit;
		if (query) {
			url += `&${query}`;
		}

		// if (!updateKategori) return axios.get(apiUrl + url, configs);
		if (!updateSales) return axios.get(apiUrl + url, configs);
		var res = await axios.get(apiUrl + url, configs);
		sales_product.value = res.data.data;
	}

	function getSaleHomeProducts(limit = 15) {
		const url = "type=sale&limit=" + limit;
		return axios.get(apiUrl + url, configs);
	}

	async function getRecommendationProducts(
		limit = 10,
		query = null,
		updateSales = false
	) {
		let url = "type=recommendation&limit=" + limit;
		if (query) {
			url += `&${query}`;
		}

		// if (!updateKategori) return axios.get(apiUrl + url, configs);
		if (!updateSales) return axios.get(apiUrl + url, configs);
		var res = await axios.get(apiUrl + url, configs);
		recommendations.value = res.data.data;
	}

	async function getTopProducts(
		limit = 10,
		query = null,
		updateSales = false
	) {
		let url = "get-top-products&limit=" + limit;
		if (query) {
			url += `&${query}`;
		}

		// if (!updateKategori) return axios.get(apiUrl + url, configs);
		if (!updateSales) return axios.get(apiUrl + url, configs);
		var res = await axios.get(apiUrl + url, configs);
		// console.log(res.data.data);
		top_product.value = res.data.data;
	}

	function getRecommendationHomeProducts(limit = 15) {
		const url = "type=recommendation&limit=" + limit;
		return axios.get(apiUrl + url, configs);
	}

	function getNewAddHomeProducts(limit = 100) {
		const url = "type=newest&limit=" + limit;
		return axios.get(apiUrl + url, configs);
	}

	function getNewAddedProducts(limit = 15, query = null, is_update = false) {
		console.log(
			"🚀 ~ file: product.js:139 ~ getNewAddedProducts ~ limit:",
			limit
		);
		if (!is_update) {
			const url = "newest=1&limit=" + limit;
			return axios.get(apiUrl + url, configs);
		}
		var url = "newest=1&limit=" + limit;
		if (query) {
			url += `&${query}`;
		}
		axios
			.get(apiUrl + url, configs)
			.then((res) => (newest_products.value = res.data.data));
	}

	async function getDrinkProducts(
		limit = 10,
		query = null,
		updateSales = false
	) {
		if (limit > 0) var url = "type=drink&limit=" + limit;
		else var url = "type=drink&";
		if (query) {
			url += `&${query}`;
		}

		// if (!updateKategori) return axios.get(apiUrl + url, configs);
		if (!updateSales) return axios.get(apiUrl + url, configs);
		var res = await axios.get(apiUrl + url, configs);
		drinks.value = res.data.data;
		// return axios.get(apiUrl + url, configs);
	}

	function getDrinkHomeProducts(limit = 15) {
		const url = "type=drink&limit=" + limit;
		return axios.get(apiUrl + url, configs);
	}

	async function getProductsLiked() {
		var res = await axios.get(
			baseUrl + "liked-products?token=" + token.value,
			configs
		);
		productsLiked.value = res.data.data;
		return res;
	}

	async function getProductViewedFilter(query = null, updateSales = false) {
		let url = "viewed-products?token=" + token.value;
		if (query) {
			url += `&${query}`;
		}

		if (!updateSales) return axios.get(baseUrl + url, configs);
		var res = await axios.get(baseUrl + url, configs);
		productViewedFilter.value = res.data.data;
	}

	async function getProductViewed() {
		var res = await axios.get(
			baseUrl + "viewed-products?token=" + token.value,
			configs
		);
		productViewed.value = res.data.data;
		return res;
	}

	async function getSearchProducts(value) {
		if (value.length < 1) {
			productsSearch.value = [];
			return;
		}
		var res = await axios
			.get(baseUrl + "product/" + value, configs)
			.then((res) => res.data);

		if (!res.success) {
			console.log("Product not Found");
			return;
		}
		productsSearch.value = res.data;
	}

	function updateAll() {
		Promise.all([
			getSaleProducts(),
			getRecommendationProducts(),
			// getDrinkProducts(),
			// getNewAddedProducts(),
			getSaleHomeProducts(),
			getRecommendationHomeProducts(),
			getTopProducts(),
			// getNewAddHomeProducts(),
			// getDrinkHomeProducts(),
			// getProductsLiked(),
		]).then(function (results) {
			console.log(results);
			sales_product.value = results[0].data.data;
			salesHome.value = results[2].data.data;
			recommendations.value = results[1].data.data;
			recommendationHome.value = results[3].data.data;
			// newest_products_home.value = results[2].data.data;
			// drinks.value = results[2].data.data;
			// drinkHome.value = results[2].data.data;
			// productsLiked.value = results[3].data.data;
			// newest_products.value = results[2].data.data;
			top_product.value = results[4].data.data;
		});
	}

	async function updateProducts() {
		var res = await axios.get(apiUrl, configs).then((res) => res.data);

		if (!res.success) {
			console.log("Something went wrong!");
			return;
		}
		products.value = res.data;
	}

	async function getProductDetails(slug) {
		var res = await axios
			.get(
				baseUrl + "product-details-slug/" + slug + "?token=" + token.value,
				configs
			)
			.then((res) => res.data);

		if (!res.success) {
			console.log("Something went wrong!");
			return;
		}
		productID.value = res.data.id;
		productDetail.value = res.data;
	}

	async function getProductReviews(id) {
		var res = await axios
			.get(baseUrl + "product-review/" + id + "?token=" + token.value, configs)
			.then((res) => res.data);

		if (!res.success) {
			console.log("Something went wrong!");
			return;
		}
		productReviews.value = res.data;
	}

	async function getProductByCategoryID(id) {
		var res = await axios
			.get(baseUrl + "products-by-category/" + id, configs)
			.then((res) => res.data);
		console.log(res.data);

		if (!res.success) {
			console.log("Something went wrong!");
			return;
		}
		products.value = res.data;
	}

	async function getCouponsByTrx(query) {
		let url = "coupons";
		if (query) {
			url += `?${query}${trx_id.value}`;
		}

		var res = await axios.get(baseUrl + url, configs);
		// console.log('ini loh', res.data.data.coupons);
		couponProduct.value = res.data.data.coupons;
	}

	async function getFilterCategoryProducts(
		id,
		query = null,
		updateCategory = false
	) {
		let url = "products-by-category/" + id;
		if (query) {
			url += `?${query}`;
		}

		// if (!updateKategori) return axios.get(apiUrl + url, configs);
		if (!updateCategory) return axios.get(baseUrl + url, configs);
		var res = await axios.get(baseUrl + url, configs);
		products.value = res.data.data;
	}

	async function addToCart(id, slug) {
		const sAuth = useAuthStore();

		if (!sAuth.token) {
			localStorage.clear();
			router.push({ name: "login" });
			return;
		}

		const sSwal = useSwalStore();

		if (
			productDetail.value.has_variant &&
			selectedProductVariant.value.id == null
		) {
			sSwal.swalNotif("Please select variant first!", 1, true);
			return;
		}

		var res = await axios
			.post(
				baseUrl + "cart-store?token=" + token.value,
				{
					product_id: id,
					quantity: 1,
					trx_id: trx_id.value,
					variants_name: selectedProductVariant.value.value,
					variants_ids: selectedProductVariant.value.id,
				},
				configs
			)
			.then((res) => res.data)
			.catch((e) => e);

		if (!res.success) {
			console.log("Something went wrong!");
			sSwal.swalNotif(res?.response?.data?.message, 1, true);
			return;
		}

		if (trx_id.value == null) {
			trx_id.value = res.data.trx_id;
		}

		await getProductDetails(slug);

		sSwal.swalNotif("Ditambahkan ke keranjang!", 0, true);
		updateCarts();
	}

	async function likeReview(id) {
		const sAuth = useAuthStore();

		if (!sAuth.token) {
			localStorage.clear();
			router.push({ name: "login" });
			return;
		}

		const sSwal = useSwalStore();

		var res = await axios
			.post(
				baseUrl + "user/like-unlike-review?token=" + token.value,
				{
					review_id: id,
				},
				configs
			)
			.then((res) => res.data)
			.catch((e) => e);

		if (!res.success) {
			console.log("Something went wrong!");
			sSwal.swalNotif(res?.response?.data?.message, 1, true);
			return;
		}

		if (productDetail.value && productDetail.value.slug != null) {
			await getProductReviews(productDetail.value.slug);
		} else {
			await getProductReviews();
		}
		await sAuth.getAllReviews();

		sSwal.swalNotif(
			res.data.like
				? "Berhasil menyukai review!"
				: "Berhasil batal menyukai review!",
			0,
			true
		);
	}

	async function addToWishlist(status, id, slug, collection_name = null) {
		const sAuth = useAuthStore();
		if (!sAuth.token) {
			localStorage.clear();
			router.push({ name: "login" });
			return;
		}
		const sSwal = useSwalStore();
		var res = await axios
			.post(
				baseUrl + "user/favourite/" + id + "?token=" + token.value,
				{ collection_name: collection_name },
				configs
			)
			.then((res) => res.data)
			.catch((e) => e);

		if (!res.success) {
			console.log("Something went wrong!");
			sSwal.swalNotif(res?.response?.data?.message, 1, true);
			return;
		}

		await getProductDetails(slug);
		// await getProductsLiked();

		if (status == true) {
			favorite.value = false;
			sSwal.swalNotif("Ditambahkan ke Disukai!", 0, true);
		} else {
			favorite.value = false;
			sSwal.swalNotif("Dikeluarkan dari Disukai!", 0, true);
		}
	}

	function toSnakeCase(str) {
		str = str.toLowerCase();
		str = str.replace(/[\W_]+/g, "_");
		str = str.replace(/([A-Z])/g, "_$1").toLowerCase();
		return str;
	}

	async function updateCarts() {
		var res = await axios
			.get(baseUrl + "carts?token=" + token.value, configs)
			.then((res) => res.data);

		if (!res.success) {
			console.log("Something went wrong!");
			return;
		}

		cart_number.value = 0;
		cart_number_items.value = 0;
		var _carts = _.groupBy(res.data.carts, "shop_name");
		_carts = _.map(_carts, (c) => {
			var _cart = {
				shop_name: c[0].shop_name != "" ? c[0].shop_name : "Murahnih Admin",
				shop_name_class:
					c[0].shop_name != ""
						? toSnakeCase(c[0].shop_name)
						: toSnakeCase("Murahnih Admin"),
				shop_image: c[0].shop_name != "" ? c[0].shop_image : logo,
				shop_selected: false,
				shop_total_items: c.length,
				shop_total: 0,
				items: [],
			};
			_.each(c, (_c) => {
				cart_number.value += _c.quantity;
				cart_number_items.value += 1;
				_cart.shop_total += _c.special_discount_active
					? parseFloat(_c.discount_price) * parseFloat(_c.quantity)
					: parseFloat(_c.price) * parseFloat(_c.quantity);
				_cart.items.push({
					id: _c.id,
					product_image: _c.product_image,
					product_name: _c.product_name,
					category_name: _c.category_name,
					category_id: _c.category_id,
					discount_price: _c.discount_price_formatted,
					price: _c.price_formatted,
					total_price: _c.formatted_sub_total,
					minimum_order_quantity: _c.minimum_order_quantity,
					special_discount_active: _c.special_discount_active,
					special_discount_type: _c.special_discount_type,
					special_discount: _c.special_discount_formatted,
					quantity: _c.quantity,
					has_variant: _c.has_variant,
					variant: _c.variant,
					note: _c.note,
					has_note: _c.has_note,
					selected: false,
				});
			});
			return _cart;
		});
		var _data = res.data;
		_data.carts = _carts;
		cart.value = _data;
		trx_id.value = cart.value.trx_id;
	}

	async function updateCartQuantity(id, qty, note = null) {
		const res = await axios
			.post(
				baseUrl + "cart-update/" + id + "?token=" + token.value,
				{ quantity: qty, note: note },
				configs
			)
			.then((res) => res.data);

		if (!res.success) {
			console.log("Something went wrong!");
			return;
		} else {
			setTimeout(() => {
				showCatatan.value = false;
			}, 1000);
		}

		await updateCarts();
	}

	async function deleteFromCart(id) {
		const sSwal = useSwalStore();
		var res = await sSwal.swalConfirmation(
			"Yakin mau dihapus",
			2,
			"",
			"Ya Hapus!",
			"Batalkan",
			"Berhasil Dihapus!",
			""
		);
		if (res) {
			var res = await axios
				.delete(baseUrl + "cart-delete/" + id + "?token=" + token.value, configs)
				.then((res) => res.data);

			if (!res.success) {
				console.log("Something went wrong!");
				return;
			}

			const sSwal = useSwalStore();
			sSwal.swalNotif("Dihapus dari keranjang!", 0, true);

			updateCarts();
		}
	}

	async function updateCartSummary() {
		console.log(cart.value);
	}

	async function submitOrder() {
		const sSwal = useSwalStore();
		const sOrder = useOrderStore();
		const sAddress = useAddressStore();

		if (!sAddress.selectedAddress?.id) {
			sSwal.swalNotif("Pilih alamat pengiriman terlebih dahulu!", 1, true);
			return false;
		}

		if (selectedShippingMethods.value.service === false) {
			sSwal.swalNotif("Pilih metode pengiriman terlebih dahulu!", 1, true);
			return false;
		}

		if (!selectedPaymentMethods.value.name) {
			sSwal.swalNotif("Pilih metode pembayaran terlebih dahulu!", 1, true);
			return false;
		}

		var _coupons = [];
		_.each(useCoupon.value, (c) => _coupons.push(c.coupon_code));

		var res = await axios
			.post(
				baseUrl + "confirm-order?token=" + token.value,
				{
					trx_id: cart.value.trx_id,
					payment: selectedPaymentMethods.value,
					address_id: sAddress.selectedAddress.id,
					shipping_service: selectedShippingMethods.value.service,
					shipping_service_type: selectedShippingMethods.value.service_type
						.toUpperCase()
						.replaceAll(" ", "_"),
					shipping_cost: sOrder.shippingCost,
					coupons: _coupons,
				},
				configs
			)
			.then((res) => res.data)
			.catch((e) => e);

		if (!res.success) {
			console.log("Something went wrong!");
			sSwal.swalNotif(
				res?.response?.data?.data?.message,
				1,
				true,
				"Please contact admin!"
			);
			return;
		}

		// if (selectedPaymentMethods.value.category == "VIRTUAL_ACCOUNT") {
		// 	sSwal.swalNotif(
		// 		"Pesanan terkirim!",
		// 		0,
		// 		true,
		// 		"Anda akan diarahkan ke halaman pembayaran"
		// 	);
		// } else {
		// }
		selectedPaymentMethods.value = {
			name: null,
			code: null,
			category: null,
		};
		sSwal
			.swalNotif(
				"Pesanan terkirim!",
				0,
				true,
				"Anda akan diarahkan ke halaman pembayaran"
			)
			.then(() => {
				window.location.href = "/order/" + res.data.invoice_no + "/payment";
			});
	}

	async function postUseCoupons(data) {
		const sSwal = useSwalStore();
		const sOrder = useOrderStore();
		const _data = {
			coupon_code: data.coupon_code,
			trx_id: trx_id.value,
			shipping_cost: sOrder.shippingCost,
		};

		var res = await axios
			.post(baseUrl + "apply-coupon?token=" + token.value, _data, configs)
			.then((res) => res.data);

		if (!res.success) {
			console.log("Something went wrong!");
			return;
		} else if (res.data?.code != undefined)
			sSwal.swalNotif(res.data?.message, 3, true);
		else {
			sSwal.swalNotif("Kupon Berhasil Digunakan!", 0, true);
			useCoupon.value.push(res.data);
			appliedCoupons.value.total = _.sumBy(useCoupon.value, "total_discount");
			appliedCoupons.value.formatted_total = parseFloat(
				appliedCoupons.value.total ?? 0
			)
				.toLocaleString("id-ID")
				.replace(".", ",");
			// await getAppliedCoupons();
		}
		return res;
	}

	async function getAppliedCoupons() {
		var res = await axios
			.get(baseUrl + "applied-coupons?trx_id=" + trx_id.value, configs)
			.then((res) => res.data);
		updateSuccess.value = res.success;
		console.log(updateSuccess.value, "update");

		if (!res.success) {
			console.log("Something went wrong!");
			// updateSuccess.value = res.success

			return;
		}
		appliedCoupons.value = res.data;
	}

	async function removeCoupons(coupon_id) {
		const sSwal = useSwalStore();
		_.remove(useCoupon.value, (c) => c.coupon_id == coupon_id);
		appliedCoupons.value.total = _.sumBy(useCoupon.value, "total_discount");
		appliedCoupons.value.formatted_total = parseFloat(
			appliedCoupons.value.total ?? 0
		)
			.toLocaleString("id-ID")
			.replace(".", ",");
		// await getAppliedCoupons();
		sSwal.swalNotif("Kupon Berhasil Dihapus!", 0, true);
	}
	//end of region actions

	return {
		products,
		productID,
		productDetail,
		productsSearch,
		productsLiked,
		productReviews,
		sales_product,
		recommendations,
		drinks,
		showDesc,
		showReview,
		cart,
		cart_number,
		cart_number_items,
		cart_summary,
		trx_id,
		showPaymentMethods,
		selectedProductVariant,
		selectedPaymentMethods,
		productViewed,
		selectedShippingMethods,
		selectedNavbarTab,
		selectedProductFilter,
		salesHome,
		recommendationHome,
		newest_products_home,
		drinkHome,
		categoryProduct,
		useCoupon,
		selectedKupon,
		appliedCoupons,
		productViewedFilter,
		selectedTabReview,
		showCatatan,
		catatanData,
		updateSuccess,
		top_product,

		newest_products,
		getNewAddedProducts,

		getProducts,
		getProductDetail,

		getTopProducts,
		getSaleHomeProducts,
		getProductViewedFilter,
		setSelectedShippingMethods,
		updateProducts,
		updateAll,
		getProductDetails,
		getProductReviews,
		getProductByCategoryID,
		addToCart,
		postUseCoupons,
		likeReview,
		updateCarts,
		updateCartQuantity,
		deleteFromCart,
		updateCartSummary,
		submitOrder,
		getSearchProducts,
		addToWishlist,
		getProductsLiked,
		getProductViewed,
		getSaleProducts,
		getRecommendationProducts,
		getRecommendationHomeProducts,
		getDrinkProducts,
		getDrinkHomeProducts,
		getFilterCategoryProducts,
		getCouponsByTrx,
		removeCoupons,
		couponProduct,
		modalKupon,
		favorite,
	};
});

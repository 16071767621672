const chatRoutes = [
	{
		path: "/chat",
		name: "chat",
		component: () => import("@/views/pages/chat/Index.vue"),
		meta: { requiresAuth: true },
	},
	{
		path: "/chat/:room_name",
		name: "chat-room",
		component: () => import("@/views/pages/chat/components/ChatRoom.vue"),
		meta: { requiresAuth: true },
	},
];

export default chatRoutes;

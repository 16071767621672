const orderRoutes = [
	{
		path: "/order",
		name: "order",
		component: () => import("@/views/pages/pesanan/Index.vue"),
		meta: { requiresAuth: true },
	},
	{
		path: "/order/:id",
		name: "order-by-id",
		component: () => import("@/views/pages/pesanan/Detail.vue"),
		meta: { requiresAuth: true },
	},
	{
		path: "/order/:invoice/payment",
		name: "order-by-invoice",
		component: () => import("@/views/pages/pesanan/Payment.vue"),
		meta: { requiresAuth: true },
	},
	{
		path: "/order/:invoice/paid-payment",
		name: "order-paid",
		component: () => import("@/views/pages/pesanan/paid_payment.vue"),
		meta: { requiresAuth: true },
	},
	{
		path: "/order/detail/sent",
		name: "detail/sent",
		component: () => import("@/views/pages/pesanan/components/sent.vue"),
	},
	{
		path: "/order/detail/done",
		name: "detail/done",
		component: () => import("@/views/pages/pesanan/components/done.vue"),
	},
	{
		path: "/order/detail/waiting-list",
		name: "order/detail/waiting-list",
		component: () => import("@/views/pages/pesanan/components/lacak-pesanan.vue"),
		meta: { requiresAuth: true },
	},
	{
		path: "/order/detail/lacak/:invoice",
		name: "order/detail/lacak",
		component: () => import("@/views/pages/pesanan/components/lacak-pesanan.vue"),
		meta: { requiresAuth: true },
	},
	{
		path: "/order/detail/ulas-produk",
		name: "order/detail/ulas-produk",
		component: () => import("@/views/pages/pesanan/components/ulasan-produk.vue"),
		meta: { requiresAuth: true },
	},
	{
		path: "/complain",
		name: "complain",
		component: () => import("@/views/pages/complain/index.vue"),
		meta: { requiresAuth: true },
	},
];

export default orderRoutes;

import { defineStore } from "pinia";
import { computed, ref } from "vue";
import axios from "axios";
import { useSwalStore } from "@/store/swal";
import { useSessionStorage } from "@vueuse/core";

export const useOtpStore = defineStore("sendOtp", () => {
	const env = process.env;
	const configs = {
		headers: {
			apiKey: env.VUE_APP_API,
		},
	};
	const baseUrl = env.VUE_APP_API_URL_LOCAL;

	async function sendOtp(data) {
		const sSwal = useSwalStore();
		var _data = {
			otp: data.code,
			email: localStorage.getItem("email"),
		};
		var res = await axios
			.post(baseUrl + "verify-otp", _data, configs)
			.then((res) => res.data)
			.catch((err) => {
				if (err.response) return err.response.data;
			});
		if (res?.success) {
			sSwal.swalNotif("verifikasi berhasil!", 0, true, "Silahkan login kembali");
		} else if (res.message == "The code OTP .")
			sSwal.swalNotif("OTP code does not match!", 1);

		return res;
	}

	async function resendOtp(reset_password = false) {
		const sSwal = useSwalStore();
		var _data = {
			email: localStorage.getItem("email"),
			reset_password: reset_password,
		};
		var res = await axios
			.post(baseUrl + "resend-otp", _data, configs)
			.then((res) => res.data)
			.catch((err) => {
				if (err.response) return err.response.data;
			});
		if (res?.success) {
			sSwal.swalNotif("kirim Otp berhasil!", 0, true, "Silahkan Cek Whatsapp");
		} else if (res.message == "The code OTP")
			sSwal.swalNotif("Otp Tidak Berhasil Terkirim!", 1);
		return res;
	}

	return {
		sendOtp,
		resendOtp,
	};
});

const authRoutes = [
	{
		path: "/login",
		name: "login",
		component: () => import("@/views/pages/login/Index.vue"),
	},

	// Register Screen
	{
		path: "/register",
		name: "register",
		component: () => import("@/views/pages/register/Index.vue"),
	},
	{
		path: "/register-verify",
		name: "register-verify",
		component: () => import("@/views/pages/register/components/otp-verify.vue"),
	},
	{
		path: "/register-complete",
		name: "register-complete",
		component: () => import("@/views/pages/register/components/complete.vue"),
	},
	{
		path: "/register-referral",
		name: "register-referral",
		component: () => import("@/views/pages/register/components/reg_ref-code.vue"),
	},
	{
		path: "/lupa-password",
		name: "lupa-password",
		component: () => import("@/views/pages/lupa-password/form-email.vue"),
	},
	{
		path: "/ubah-lupa-password",
		name: "ubah-lupa-password",
		component: () => import("@/views/pages/lupa-password/form-ubah-password.vue"),
	},
	{
		path: "/otp-lupa-password",
		name: "otp-lupa-password",
		component: () => import("@/views/pages/lupa-password/form-otp-main.vue"),
	},
	// End of Register Screen
];

export default authRoutes;

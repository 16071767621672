import { defineStore } from "pinia";
import { computed, ref } from "vue";
import axios from "axios";
import { useLocalStorage } from "@vueuse/core";

export const useBannerStore = defineStore("banner", () => {
  //region states
  const env = process.env;
  const configs = { headers: { apiKey: env.VUE_APP_API } };
  const baseUrl = env.VUE_APP_API_URL_LOCAL;
  const apiUrl = baseUrl + "banners"; // URL untuk endpoint banners
  
  const bannersData = ref([]); 
  //end of region states

  //region getters
  const getBanners = computed(() => bannersData.value); 
  //end of region getters

  //region actions
  async function fetchBannersData() { 
    try {
      const response = await axios.get(apiUrl, configs);
      const data = response.data;
      // console.log(data);

      if (!data.success) {
        console.log("Something went wrong!");
        return;
      }

      bannersData.value = data.data;
    } catch (error) {
      console.error("An error occurred while fetching banners:", error);
    }
  }
  //end of region actions

  return {
    bannersData, 
    getBanners,
    fetchBannersData, 
  };
});

import { defineStore } from "pinia";
import { computed, ref } from "vue";
import axios from "axios";
import { useLocalStorage } from "@vueuse/core";

export const useCategoryStore = defineStore("category", () => {
	//region states
	const env = process.env;
	const configs = { headers: { apiKey: env.VUE_APP_API } };
	const baseUrl = env.VUE_APP_API_URL_LOCAL;
	const apiUrl = baseUrl + "category/all";
	const categoryID = ref(null);
	const selectedCategory = ref(
		useLocalStorage("selectedCategory", { id: null, name: "" })
	);
	const categories = ref(useLocalStorage("categories", []));
	//end of region states

	//region getters
	const getCategories = computed(() => categories);
	//end of region getters

	//region actions
	async function updateCategories() {
		var res = await axios.get(apiUrl, configs).then((res) => res.data);

		if (!res.success) {
			console.log("Something went wrong!");
			return;
		}
		categories.value = res.data.categories;
	}
	//end of region actions

	return {
		categories,
		categoryID,
		selectedCategory,
		getCategories,
		updateCategories,
	};
});

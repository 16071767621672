import { defineStore } from "pinia";
import { computed, ref } from "vue";
import axios from "axios";

export const useAdvantageStore = defineStore("advantage", () => {
	//region states
	const current = ref(1);

	const datas = ref([
		{
			img: require("@/assets/img/section-start/first-screen-1.png"),
			img_alt: "first-screen-1.png",
			title: "Produk Higienis",
			subtitle: "Produk di platform kami terjamin higienis dan layak konsumsi",
		},
		{
			img: require("@/assets/img/section-start/second-screen-1.png"),
			img_alt: "second-screen-1.png",
			title: "Tanpa MarkUp",
			subtitle: "Dapetin potongan harga hingga 50% tanpa adanya biaya tambahan",
		},
		{
			img: require("@/assets/img/section-start/first-screen-1.png"),
			img_alt: "first-screen-1.png",
			title: "Produk Higienis",
			subtitle: "Produk di platform kami terjamin higienis dan layak konsumsi",
		},
		{
			img: require("@/assets/img/section-start/second-screen-1.png"),
			img_alt: "second-screen-1.png",
			title: "Tanpa MarkUp",
			subtitle: "Dapetin potongan harga hingga 50% tanpa adanya biaya tambahan",
		},
	]);
	//end of region states

	//region getters
	const dataLength = computed(() => datas.value.length);
	//end of region getters

	//region actions
	async function updateAdvantages() {
		var res = await axios
			.get(
				"http://127.0.0.1/murahnih_core/api/v100/get-products?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOi8vMTI3LjAuMC4xL211cmFobmloX2NvcmUvYXBpL3YxMDAvbG9naW4iLCJpYXQiOjE2NzYwMTg2MjcsIm5iZiI6MTY3NjAxODYyNywianRpIjoiTExyejl5bTE0ZEtiM0VBQiIsInN1YiI6IjUiLCJwcnYiOiIyM2JkNWM4OTQ5ZjYwMGFkYjM5ZTcwMWM0MDA4NzJkYjdhNTk3NmY3IiwiaWQiOjUsIm5hbWUiOm51bGwsImVtYWlsIjoiYmFuYWJkYUBnbWFpbC5jb20iLCJjcmVhdGVkX2F0IjoiMjAyMy0wMi0xMFQwNzozNjo0OS4wMDAwMDBaIiwidXBkYXRlZF9hdCI6IjIwMjMtMDItMTBUMDc6NTQ6MTEuMDAwMDAwWiJ9.NWg2zY3r_zGotXiLXnEUyp5FvM67fyDK3maRBHAqVhQ",
				{
					headers: {
						apiKey: "RHBCFGZ7YRXCKRBZ",
					},
				}
			)
			.then((res) => res.data);
		if (!res.success) {
			console.log("Something went wrong!");
			return;
		}
		datas.value = res.data;
	}
	function next() {
		current.value++;
	}
	function prev() {
		current.value--;
	}
	//end of region actions

	return { current, datas, dataLength, next, prev };
});

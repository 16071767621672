import { defineStore } from "pinia";
import { computed, ref } from "vue";
import axios from "axios";
import { useLocalStorage } from "@vueuse/core";

export const useOrderStore = defineStore("order", () => {
	const env = process.env;
	const configs = {
		headers: {
			apiKey: env.VUE_APP_API,
		},
	};
	const baseUrl = env.VUE_APP_API_URL_LOCAL;
	const token = ref(useLocalStorage("token", ""));
	const selectedOrder = ref(useLocalStorage("selectedOrder", {}));

	const orders = ref([]);
	const orderDetail = ref(null);
	const trackingDetail = ref(null);
	const shippingMethods = ref([]);
	const selectedTabOrder = ref("");
	const shippingCost = ref(0);

	async function getOrders() {
		var res = await axios
			.get(baseUrl + "orders?token=" + token.value, configs)
			.then((res) => res.data);
		orders.value = res.data.orders;
	}

	async function getOrderByInvoice(invoiceNo) {
		var res = await axios
			.get(
				baseUrl + "track-order?invoice_no=" + invoiceNo + "&token=" + token.value,
				configs
			)
			.then((res) => res.data);
		orderDetail.value = res.data.order;
		selectedOrder.value = res.data.order;
	}

	async function getTrackingByInvoice(invoiceNo) {
		var res = await axios
			.get(
				baseUrl +
					"track-shipping?invoice_no=" +
					invoiceNo +
					"&token=" +
					token.value,
				configs
			)
			.then((res) => res.data);
		trackingDetail.value = res.data.order;
	}

	async function getShippingCost(service_type, cart_id, address_id) {
		const transGrabService = {
			1: "INSTANT",
			2: "SAME_DAY",
		};
		var _data = {
			// service_type: transGrabService[service_type],
			cart_id: cart_id,
			address_id: address_id,
		};
		try {
			const data = await axios
				.post(baseUrl + "shipping-estimation", _data, configs)
				.then((res) => res.data);

			if (!data?.data) {
				console.log("something went wrong!");
				return;
			}
			shippingMethods.value = data.data;
		} catch (error) {
			console.error("An error occurred while getting shipping cost:", error);
		}
	}

	function setShippingCost(cost) {
		shippingCost.value = cost;
	}

	return {
		orders,
		orderDetail,
		trackingDetail,
		shippingCost,
		shippingMethods,
		selectedTabOrder,
		selectedOrder,

		getOrders,
		getOrderByInvoice,
		getTrackingByInvoice,
		getShippingCost,
		setShippingCost,
	};
});

import { defineStore } from "pinia";
import { computed, ref } from "vue";
import axios from "axios";
import { useLocalStorage, useSessionStorage } from "@vueuse/core";
import { useSwalStore } from "@/store/swal";
import { useRouter } from "vue-router";
import { useOtpStore } from "./otp";

export const useAuthStore = defineStore("auth", () => {
	const router = useRouter();
	const env = process.env;
	const configs = {
		headers: {
			apiKey: env.VUE_APP_API,
		},
	};
	const baseUrl = env.VUE_APP_API_URL_LOCAL;

	const token = ref(useLocalStorage("token", ""));
	const user = ref(useLocalStorage("user", {}));
	const wallet = ref(useLocalStorage("wallet", {}));
	const resetPasswordData = ref(useLocalStorage("resetPasswordData", {}));
	const resetPasswordToken = ref(useSessionStorage("resetPasswordToken", {}));
	const addressID = ref(null);
	const emailStore = ref(0);
	// const loginStore = ref(0);
	const dataLogin = ref({});
	const dataChangePassword = ref(0);

	const ulasanModal = ref(false);
	const ulasanData = ref({
		rating: 0,
		title: "",
		comment: "",
		product_id: null,
		order_detail_id: null,
		file: "",
	});

	const isAuthenticated = computed(() => {
		return !!token.value; // Mengembalikan true jika token ada, sebaliknya false
	});

	const productReviews = ref([]);
	const productNotReviews = ref([]);

	function updateUser(value) {
		user.value = value;
	}

	function updateToken(value) {
		token.value = value;
	}

	function redirectToRegisterVerify() {
		router.push({ name: "register-verify" });
	}

	async function updatePasswordUser(data) {
		const sSwal = useSwalStore();
		const dataPembaruan = {
			current_password: data.current_password,
			new_password: data.new_password,
			confirm_password: data.confirm_password,
		};

		const res = await axios
			.post(
				baseUrl + "user/change-password?token=" + token.value,
				dataPembaruan,
				configs
			)
			.then((res) => res.data)
			.catch((err) => {
				if (err.response) return err.response.data;
			});

		if (res?.success) {
			sSwal.swalNotif("Kata sandi berhasil diperbarui!", 0, true);
		} else if (res?.message) {
			sSwal.swalNotif(res.message, 1);
		} else {
			sSwal.swalNotif("Terjadi kesalahan. Silakan coba lagi nanti.", 1);
		}

		return res;
	}

	async function updateWallet() {
		var res = await axios
			.get(baseUrl + "user/wallet?token=" + token.value, configs)
			.then((res) => res.data)
			.catch((err) => {
				if (err.response) return err.response.data;
			});
		if (res.success) wallet.value = res.data;
	}

	async function getUserProfile() {
		var res = await axios
			.get(baseUrl + "user/profile?token=" + token.value, configs)
			.then((res) => res.data)
			.catch((err) => {
				if (err.response) return err.response.data;
			});
		updateUser(res.data);
		return res;
	}

	async function updateProfileUser(data) {
		const sSwal = useSwalStore();
		const res = await axios
			.post(baseUrl + "user/update-profile?token=" + token.value, data, configs)
			.then((res) => res.data)
			.catch((err) => {
				if (err.response) return err.response.data;
			});

		if (res?.success) {
			sSwal.swalNotif("Profil User berhasil diperbarui!", 0, true);
			await getUserProfile();
		} else if (res?.message) {
			sSwal.swalNotif(res.message, 1);
		} else {
			sSwal.swalNotif("Terjadi kesalahan. Silakan coba lagi nanti.", 1);
		}

		return res;
	}

	async function login(data) {
		const sSwal = useSwalStore();
		var _data = {
			email: data.email,
			password: data.password,
			// email: btoa(data.email),
			// password: btoa(data.password),
		};
		var res = await axios
			.post(baseUrl + "login", _data, configs)
			.then((res) => res.data)
			.catch((err) => {
				if (err.response) return err.response.data;
			});

		if (res?.success) {
			updateToken(res.data.token);
			// loginStore.value = 1

			var res = await getUserProfile();
		} else if (res.message == "User not found") {
			sSwal.swalNotif("Email tidak ditemukan", 1);
		} else if (res.message == "Invalid credentials") {
			sSwal.swalNotif("Password salah!", 1);
		} else if (
			res.message == "Your account is not verified. Please verify your account." ||
			res.message == "Your account status is inactive"
		) {
			redirectToRegisterVerify();
			dataLogin.value = res.data;
			sSwal.swalNotif("Email belum di verifikasi!", 2);
		}
		// } else if (res.message.trim() == "Your account is not verified. Please verify your account." || res.message.trim() == "Your account status is inactive") {
		// 	redirectToRegisterVerify()
		// 	sSwal.swalNotif("Email belum di verifikasi!", 2);
		// }
		return res;
	}

	async function resetPassword(data) {
		const sSwal = useSwalStore();
		// Check if any required field is empty
		if (!data.email || !data.phone) {
			sSwal.swalNotif("Tolong Isi Seluruh Inputan.", 1, false);
			return;
		}
		var _data = {
			email: data.email,
			phone: data.phone,
		};
		var res = await axios
			.post(baseUrl + "forgot-password", _data, configs)
			.then((res) => res.data)
			.catch((err) => {
				if (err.response) return err.response.data;
				sSwal.swalNotif("isi seluruh field, silahkan coba lagi!", 1, false);
			});
		if (res?.success) {
			localStorage.setItem("email", _data.email);
			localStorage.setItem("phone", _data.phone);
			sSwal.swalNotif("Request Reset Password berhasil!", 0, true);
		}
		return res;
	}

	async function sendVefirikasi(data) {
		const sSwal = useSwalStore();
		// Check if any required field is empty
		var _data = {
			otp: data.code,
			email: localStorage.getItem("email"),
			phone: localStorage.getItem("phone"),
		};
		var res = await axios
			.post(baseUrl + "verify-reset-otp", _data, configs)
			.then((res) => res.data)
			.catch((err) => {
				if (err.response) return err.response.data;
				sSwal.swalNotif("isi seluruh field, silahkan coba lagi!", 1, false);
			});
		if (res?.success) {
			localStorage.setItem("email", _data.email);
			localStorage.setItem("phone", _data.phone);
			localStorage.setItem("otp", _data.otp);
			sSwal.swalNotif("OTP terverifikasi!", 0, true);
			resetPasswordToken.value = res?.data;
		} else {
			sSwal.swalNotif(res?.message, 1, true);
		}
		return res;
	}

	async function confirmPassword(data) {
		const sSwal = useSwalStore();
		const sOtp = useOtpStore();
		// Check if any required field is empty
		var _data = {
			password: data.password,
			password_confirmation: data.password_confirmation,
			email: localStorage.getItem("email"),
			phone: localStorage.getItem("phone"),
			otp: localStorage.getItem("otp"),
		};
		var res = await axios
			.post(baseUrl + "reset-password", _data, configs)
			.then((res) => res.data)
			.catch((err) => {
				if (err.response) return err.response.data;
				sSwal.swalNotif("isi seluruh field, silahkan coba lagi!", 1, false);
			});
		if (res?.success) {
			sSwal.swalNotif("Password berhasil diubah!", 0, true);
			sOtp.resetPasswordToken = {};
		}
		return res;
	}

	async function register(data) {
		const sSwal = useSwalStore();
		var _data = {
			first_name: data.first_name,
			last_name: data.last_name,
			phone: data.phone,
			email: data.email,
			password: btoa(data.password),
			password_confirmation: btoa(data.conf_password),
		};
		var res = await axios
			.post(baseUrl + "register", _data, configs)
			.then((res) => res.data)
			.catch((err) => {
				if (err.response) return err.response.data;
			});
		if (res?.success) {
			localStorage.setItem("email", _data.email);
			localStorage.setItem("phone", _data.phone);
			sSwal.swalNotif(
				"Daftar berhasil!",
				0,
				true,
				"Silahkan verifikasi email dan login kembali"
			);
		} else if (res.message == "The email has already been taken.")
			sSwal.swalNotif("Email sudah digunakan!", 1);
		else if (res.data?.phone && res.data.phone.length > 0) {
			sSwal.swalNotif(res.data.phone[0], 1); // Menampilkan pesan kesalahan "The phone has already been taken."
		} else {
			sSwal.swalNotif("Terjadi kesalahan. Harap coba lagi nanti.", 1); // Pesan kesalahan umum jika tidak ada pesan kesalahan spesifik.
		}

		return res;
	}

	async function logout() {
		const sSwal = useSwalStore();
		if (token.value) {
			var res = await axios
				.post(baseUrl + "user/logout?token=" + token.value, {}, configs)
				.then((res) => res.data)
				.catch((err) => {
					if (err.response) return err.response.data;
				});
			if (res?.success) {
				sSwal.swalNotif("Logout Berhasil!", 0, true);
				localStorage.clear();
			}
			return res;
		}
	}

	async function getAllReviews() {
		var res = await axios
			.get(baseUrl + "user/product-review?token=" + token.value, configs)
			.then((res) => res.data);

		if (!res.success) {
			console.log("Something went wrong!");
			return;
		}
		productReviews.value = res.data;
	}

	async function getNotReviewProducts() {
		var res = await axios
			.get(baseUrl + "unreview-product?token=" + token.value, configs)
			.then((res) => res.data);

		if (!res.success) {
			console.log("Something went wrong!");
			return;
		}
		productNotReviews.value = res.data;
	}

	async function submitUlasan(data) {
		const sSwal = useSwalStore();
		// Check if any required field is empty
		if (
			!ulasanData.value.product_id ||
			!ulasanData.value.comment ||
			!ulasanData.value.rating
		) {
			sSwal.swalNotif("Tolong Isi Seluruh Inputan.", 1, false);
			return;
		}

		// Create a FormData object to handle file uploads
		var formData = new FormData();

		// Append the text fields to the FormData
		formData.append("order_detail_id", ulasanData.value.order_detail_id);
		formData.append("product_id", ulasanData.value.product_id);
		formData.append("comment", ulasanData.value.comment);
		formData.append("rating", ulasanData.value.rating);

		if (ulasanData.value.file.length > 0) {
			// Append the single selected file to the FormData
			formData.append("image", ulasanData.value.file[0].file);
			console.log(ulasanData.value.file[0], "coba di pinia");
		}
		var res = await axios
			.post(
				baseUrl + "user/submit-review?token=" + token.value,
				formData,
				configs,
				{
					headers: {
						"Content-Type": "multipart/form-data", // Ensure proper content type
					},
				}
			)
			.then((res) => res.data)
			.catch((err) => {
				if (err.response) return err.response.data;
				sSwal.swalNotif("isi seluruh field, silahkan coba lagi!", 1, false);
			});
		if (res?.success) sSwal.swalNotif("Submit Ulasan berhasil!", 0, true);
		return res;
	}

	return {
		token,
		user,
		wallet,
		resetPasswordData,
		resetPasswordToken,
		productReviews,
		productNotReviews,
		ulasanModal,
		ulasanData,
		dataLogin,
		updateUser,
		updateWallet,
		getUserProfile,
		updateToken,
		login,
		register,
		logout,
		dataChangePassword,
		// loginStore,
		emailStore,
		isAuthenticated,
		updatePasswordUser,
		updateProfileUser,
		resetPassword,
		sendVefirikasi,
		confirmPassword,
		getAllReviews,
		getNotReviewProducts,
		submitUlasan,
	};
});

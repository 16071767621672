import { defineStore } from "pinia";
import { computed, ref } from "vue";
import axios from "axios";
import { useLocalStorage, useSessionStorage } from "@vueuse/core";
import { useAuthStore } from "./auth";

export const useChatStore = defineStore("chat", () => {
	const env = process.env;
	const configs = {
		headers: {
			apiKey: env.VUE_APP_API,
		},
	};
	const baseUrl = env.VUE_APP_API_URL_LOCAL;

	const chats = ref([]);
	const selectedChat = ref({});
	const messages = ref([]);
	const socket = ref(null);
	const numberUnreadChats = ref(0);

	async function setSelectedChat(data) {
		selectedChat.value = data;
	}

	async function getChats() {
		const sAuth = useAuthStore();
		var res = await axios
			.get(baseUrl + "user/chats?token=" + sAuth.token, configs)
			.then((res) => res.data);
		chats.value = res.data;
	}

	async function createChat(slug) {
		const sAuth = useAuthStore();
		var res = await axios
			.get(
				baseUrl + "user/chats/create/" + slug + "?token=" + sAuth.token,
				configs
			)
			.then((res) => res.data);
		console.log("🚀 ~ file: chat.js:42 ~ createChat ~ res:", res);
		// selectedChat.value = res.data;
		selectedChat.value.chat_id = res.data.chat_id;
		selectedChat.value.customer_id = res.data.customer_id;
		selectedChat.value.seller = res.data.seller;
		selectedChat.value.seller_id = res.data.seller_id;
	}

	async function getUnreadChats() {
		const sAuth = useAuthStore();
		var res = await axios
			.get(baseUrl + "user/chats/unread-messages?token=" + sAuth.token, configs)
			.then((res) => res.data)
			.catch((err) => console.log(err));
		if (res?.data?.number_unread) {
			numberUnreadChats.value = res.data.number_unread;
		}
	}

	async function getMessages() {
		const sAuth = useAuthStore();
		var res = await axios
			.get(
				baseUrl +
					"user/chats/by-seller/" +
					selectedChat.value.seller_id +
					"?token=" +
					sAuth.token,
				configs
			)
			.then((res) => res.data);
		messages.value = res.data;
	}

	async function sendMessage(message) {
		const sAuth = useAuthStore();
		var _data = {
			customer_id: selectedChat.value.customer_id,
			seller_id: selectedChat.value.seller_id,
			chat_id: selectedChat.value.chat_id,
			product_id: selectedChat.value.product,
			message: message,
		};
		var res = await axios
			.post(
				baseUrl + "user/chats/send-message?token=" + sAuth.token,
				_data,
				configs
			)
			.then((res) => res.data);

		if (selectedChat.value.product) {
			delete selectedChat.value.product;
			delete selectedChat.value.product_detail;
		}

		socket.value.emit("send-chat-by-chat_id", _data);
	}

	async function getProductDetails(id) {
		var res = await axios
			.get(baseUrl + "product-details/" + id, configs)
			.then((res) => res.data);

		if (!res.success) {
			console.log("Something went wrong!");
			return;
		}
		selectedChat.value.product_detail = res.data;
	}

	return {
		chats,
		selectedChat,
		messages,
		socket,
		numberUnreadChats,

		setSelectedChat,
		getChats,
		createChat,
		getUnreadChats,
		getMessages,
		sendMessage,
		getProductDetails,
	};
});

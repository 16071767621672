import { defineStore } from "pinia";
import { computed, ref } from "vue";
import axios from "axios";
import { useLocalStorage } from "@vueuse/core";
import { useSwalStore } from "@/store/swal";

export const useAddressStore = defineStore("address", () => {
	const env = process.env;
	const configs = {
		headers: {
			apiKey: env.VUE_APP_API,
		},
	};
	const baseUrl = env.VUE_APP_API_URL_LOCAL;
	const countryID = ref(null);
	const cityID = ref(null);
	const stateID = ref(null);
	const addressID = ref(null);
	const mainAddressIndex = ref(null);
	const dropdownAddress = ref(false)
	const dDownActive = ref(false)
	

	// const token = localStorage.getItem("token");
	const token = ref(useLocalStorage("token"));
	const addressDetail = ref(null);

	// const apiUrl = baseUrl + "user/address?token=" + token.value; // URL untuk endpoint banners
	const apiCountry = baseUrl + "countries";

	const addressData = ref([]);

	const selectedAddress = ref(useLocalStorage("selectedAddress", {}));
	const shippingDistance = ref(0);

	const countryData = ref([]);

	const stateData = ref([]);

	const cityData = ref([]);

	const cityKota = ref([]);

	const getAddress = computed(() => addressData.value);

	const getCountry = computed(() => countryData.value);

	const getState = computed(() => stateData.value);

	const getCity = computed(() => cityData.value);

	const getCityJakarta = computed(() => cityKota.value)

	const isMainAddressSet = computed(() => mainAddressIndex.value !== null);

	const updateAddressStore = ref(0);

	// Tambahkan action untuk mengubah nilai countryID
	function setCountryID(id) {
		countryID.value = id;
	}

	function setStateID(id) {
		stateID.value = id;
	}

	function setCityID(id) {
		cityID.value = id;
	}

	function setSelectedAddress(data) {
		selectedAddress.value = data;
		// countryID.value = data.country_id;
		// stateID.value = data.state_id;
		// cityID.value = data.city_id;
		// localStorage.setItem("selectedAddress", data);
	}

	function setShippingDistance(data) {
		shippingDistance.value = data;
	}

	async function setMainAddress(index) {
		mainAddressIndex.value = index;
	}

	async function addAddress(data) {
		const sSwal = useSwalStore();
		// Check if any required field is empty
		if (
			!data.name ||
			!data.email ||
			!data.phone_no ||
			!data.country_id ||
			!data.state_id ||
			!data.city_id ||
			!data.postal_code ||
			!data.label ||
			!data.address ||
			!data.latitude ||
			!data.longitude
		) {
			sSwal.swalNotif("Tolong Isi Seluruh Inputan.", 1, false);
			return;
		}
		var _data = {
			name: data.name,
			email: data.email,
			phone_no: data.phone_no,
			country_id: data.country_id,
			state_id: data.state_id,
			city_id: data.city_id,
			postal_code: data.postal_code,
			label: data.label,
			address: data.address,
			latitude: data.latitude,
			longitude: data.longitude,
		};
		var res = await axios
			.post(baseUrl + "user/address?token=" + token.value, _data, configs)
			.then((res) => res.data)
			.catch((err) => {
				if (err.response) return err.response.data;
				sSwal.swalNotif("isi seluruh field, silahkan coba lagi!", 1, false);
			});
		if (res?.success) sSwal.swalNotif("Tambah Alamat berhasil!", 0, true);
		return res;
	}

	async function updateAddress(data) {
		const sSwal = useSwalStore();
		// Periksa jika ada field yang diperlukan yang kosong
		if (
			!data.id ||
			!data.name ||
			!data.email ||
			!data.phone_no ||
			!data.country_id ||
			!data.state_id ||
			!data.city_id ||
			!data.postal_code ||
			!data.label ||
			!data.address ||
			!data.latitude ||
			!data.longitude
		) {
			sSwal.swalNotif("Harap Isi Semua Kolom.", 1, false);
			return;
		}
	
		// const url = baseUrl + "user/update-address?token=" + token.value;
		const payload = {
			id: data.id,
			name: data.name,
			email: data.email,
			phone_no: data.phone_no,
			country_id: data.country_id,
			state_id: data.state_id,
			city_id: data.city_id,
			postal_code: data.postal_code,
			label: data.label,
			address: data.address,
			latitude: data.latitude,
			longitude: data.longitude,
		};

		var res = await axios
			.post(baseUrl + "user/update-address?token=" + token.value, payload, configs)
			.then((res) => res.data)
			.catch((err) => {
				if (err.response) return err.response.data;
				sSwal.swalNotif("Gagal memperbarui alamat. Silakan coba lagi!", 1, false);
			});
		if (res?.success) sSwal.swalNotif("Edit Alamat berhasil!", 0, true);
		return res;
	}

	async function makeDefaultAddress(data) {
		const sSwal = useSwalStore();
		// const url = baseUrl + "user/update-address?token=" + token.value;
		const dataDefault = {
			id: data.id,
			type: data.type,
		};

		var res = await axios
			.post(baseUrl + "user/make-default?token=" + token.value, dataDefault, configs)
			.then((res) => res.data)
			.catch((err) => {
				if (err.response) return err.response.data;
				sSwal.swalNotif("Gagal menjadikan alamat utama. Silakan coba lagi!", 1, false);
			});
		if (res?.success) {sSwal.swalNotif("Add alamat utama berhasil!", 0, true);
			await fetchAddressData();
		};
		return res
	}

	async function fetchAddressData(default_address = true) {
		if (token.value)
			try {
				const response = await axios.get(
					baseUrl + "user/address?token=" + token.value,
					configs
				);
				const data = response.data;

				if (!data.addresses) {
					console.log("something went wrong!");
					return;
				}

				_.each(data.addresses, (a) => {
					if (a.default_shipping) {
						if (default_address && selectedAddress.value?.id == undefined)
							selectedAddress.value = a;
						mainAddressIndex.value = a.id;
					}
				});

				addressData.value = data.addresses; // Use data.addresses instead of data.data
			} catch (error) {
				console.error("An error occurred while fetching address:", error);
			}
	}

	async function fetchCountryData() {
		try {
			const response = await axios.get(apiCountry, configs);
			const data = response.data;

			if (!data.countries) {
				console.log("something went wrong!");
				return;
			}

			countryData.value = data.countries; // Use data.addresses instead of data.data
		} catch (error) {
			console.error("An error occurred while fetching country:", error);
		}
	}

	async function fetchStateData() {
		try {
			const response = await axios.get(
				baseUrl + "states/" + countryID.value,
				configs
			);
			const data = response.data;
			if (!data.states) {
				console.log("something went wrong!");
				return;
			}

			stateData.value = data.states;
		} catch (error) {
			console.error("An error occurred while fetching country:", error);
		}
	}

	async function fetchCityData() {
		try {
			const response = await axios.get(
				baseUrl + "cities/" + stateID.value,
				configs
			);
			const data = response.data;

			if (!data.cities) {
				console.log("something went wrong!");
				return;
			}

			cityData.value = data.cities;
		} catch (error) {
			console.error("An error occurred while fetching country:", error);
		}
	}
	
	async function fetchCityByCountryStateAndCityID() {
    try {
        // Ambil data kota (cities) berdasarkan country_id, state_id, dan city_id
        const response = await axios.get(
            baseUrl + "cities/" + 6,
            configs
        );
        const data = response.data;

        if (!data.cities) {
            console.log("Something went wrong!");
            return;
        }

        // Simpan data kota ke dalam variabel cityKota
        cityKota.value = data.cities;
    } catch (error) {
        console.error("An error occurred while fetching city:", error);
    }
}

	async function getAddressDetails(id) {
		addressID.value = id;
		var res = await axios
			.get(
				baseUrl + "user/address" + addressID.value + "?token=" + token.value,
				configs
			)
			.then((res) => res.data);

		if (!res.success) {
			console.log("Something went wrong!");
			return;
		}
		addressDetail.value = res.data;
	}

	return {
		addressData,
		getAddress,
		selectedAddress,
		shippingDistance,
		countryData,
		getCountry,
		stateData,
		cityData,
		getState,
		getCity,
		countryID,
		stateID,
		addressDetail,
		getCityJakarta,
		cityKota,
		updateAddressStore,
		dropdownAddress,
		dDownActive,
		setCountryID,
		setStateID,
		setSelectedAddress,
		setShippingDistance,
		fetchAddressData,
		fetchCountryData,
		fetchStateData,
		fetchCityData,
		addAddress,
		setCityID,
		setMainAddress,
		mainAddressIndex,
		isMainAddressSet,
		getAddressDetails,
		updateAddress,
		makeDefaultAddress,
		fetchCityByCountryStateAndCityID,
	};
});

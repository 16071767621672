import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "./style/app.css";
import VueDragscroll from "vue-dragscroll";
import { createPinia } from "pinia";
import { Loader } from "@googlemaps/js-api-loader";
import VueCountdown from "@chenfengyuan/vue-countdown";

const loader = new Loader({
	apiKey: "AIzaSyD0n7NttGgYsWa-Rzir67FVFOe7IrEKK8M",
	version: "weekly",
	libraries: ["places", "geometry"],
});
loader.load();
const pinia = createPinia();

createApp(App)
	.use(pinia)
	.use(router)
	.use(VueDragscroll)
	.component(VueCountdown.name, VueCountdown)
	.mount("#app");

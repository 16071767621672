const homeRoutes = [
	{
		path: "/home",
		name: "home",
		component: () => import("@/views/pages/home/Index.vue"),
		// meta: { requiresAuth: true },
	},
	{
		path: "/search",
		name: "search",
		component: () => import("@/views/pages/home/components/home-search.vue"),
		// meta: { requiresAuth: true },
	},
	{
		path: "/category/:id",
		name: "byCategory",
		component: () => import("@/views/pages/kategori/Index.vue"),
		// meta: { requiresAuth: true },
	},
	{
		path: "/filter",
		name: "filter",
		component: () => import("@/views/layouts/filter.vue"),
	},
	{
		path: "/notifications",
		name: "notifications",
		component: () => import("@/views/pages/notification/index.vue"),
	},
	{
		path: "/add-address",
		name: "add-address",
		component: () => import("@/views/pages/profile/alamat/index.vue"),
		meta: { requiresAuth: true },
	},
	{
		path: "/address/:id",
		name: "byAddress",
		component: () => import("@/views/pages/profile/alamat/update_address.vue"),
		meta: { requiresAuth: true },
	},
	{
		path: "/ulasan",
		name: "ulasan",
		component: () => import("@/views/pages/ulasan/index.vue"),
		meta: { requiresAuth: true },
	},
];

export default homeRoutes;
